import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { themeColors } from '../../styles';
import useDataLastServices from '../../hooks/useDataLastServices';
import Card from '../Home/Card';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { DocumentEnum } from '../../types/documentEnum';

const LastOrders = () => {
    const {data} = useDataLastServices()
    const navigate = useNavigate()
    return (
        <Box>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'10px'}}>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                קריאות שירות אחרונות
                </Typography>
                <IconButton onClick={() => navigate(`${URLS.DOCUMENTS.LINK}/${DocumentEnum.SERVICES}`)}>
                    <ArrowBackIosNewIcon sx={{color:themeColors.title}}/>
                </IconButton>
            </Box>
            <Box>
                {data?.slice(0, 2).map((item) => (
                    <Card key={item.documentNumber} item={item} isOrder={false} />
                ))}
            </Box>
        </Box>
    );
};

export default LastOrders;