import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { DocumentService } from '../services/document.service'
import { useArea } from '../store/area.store'

const fetchData = async (
    userExId: string,
    areaCode: string,
    isAvaliable: boolean = false
): Promise<IDocument[]> => {
  return await DocumentService.getLastDocuments(userExId,areaCode,isAvaliable)
}

const useDataLastDocuments = (isAvaliable: boolean = false) => {
  const { user } = useAuth()
  const {choosedArea} = useArea()
  const { data, error, isLoading, mutate } = useSWR<IDocument[]>(
   `/client/LastDocuments/${user?.isPayCustomer ? '200' :choosedArea?.code}/${user?.isPayCustomer ? choosedArea?.code : user?.extId}${isAvaliable ? '?type=avaliable' :''}`,
    () => fetchData(user?.isPayCustomer ? '200' :choosedArea?.code!,user?.isPayCustomer ? choosedArea?.code! : user?.extId!,isAvaliable)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataLastDocuments

