import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Home from '../Home';
import { themeColors } from '../../styles';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { DocumentEnum } from '../../types/documentEnum';

const LastOrders = () => {
    const { data } = useDataLastOrders();
    const navigate = useNavigate()
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '20px' }}>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                    הזמנות שביצעת לאחרונה
                </Typography>
                <IconButton onClick={() => navigate(`${URLS.DOCUMENTS.LINK}/${DocumentEnum.ORDERS}`)}>
                    <ArrowBackIosRoundedIcon sx={{ color: themeColors.title }} />
                </IconButton>
            </Box>

            <Box>
                {data?.slice(0, 2).map((item) => (
                    <Home.Card key={item.documentNumber} item={item} isOrder={true} />
                ))}
            </Box>
        </Box>
    );
};

export default LastOrders;
