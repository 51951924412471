import { Box, TextField } from '@mui/material';
import React from 'react';
import { useService } from '../../store/service.store';

const Filter = () => {
    const {search,setSearch} = useService()
    return (
        <Box sx={{padding:'0 10px'}}>
            <TextField id="outlined-basic" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='חפשו לפי שם מכונה, מס״ד' variant="outlined" fullWidth/>
        </Box>
    );
};

export default Filter;