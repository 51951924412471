import { Box, Paper, Rating, Typography, Chip, TextField, Button, CircularProgress } from '@mui/material';
import useIllustration from '../../hooks/useIllustration';
import { QuestionEnum } from '../../types/questionEnum';
import { themeColors } from '../../styles';
import CustomCircularProgress from '../Common/Preloader';

const FullIcon = () => (
    <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.3043 5.75561C19.6885 4.97738 19.8806 4.58826 20.1413 4.46394C20.3682 4.35577 20.6317 4.35577 20.8586 4.46394C21.1193 4.58826 21.3114 4.97738 21.6956 5.75561L25.34 13.1389C25.4534 13.3686 25.5101 13.4835 25.593 13.5727C25.6664 13.6517 25.7544 13.7157 25.8521 13.7611C25.9625 13.8125 26.0893 13.831 26.3428 13.868L34.4949 15.0596C35.3534 15.1851 35.7826 15.2478 35.9812 15.4575C36.1541 15.6399 36.2353 15.8906 36.2024 16.1397C36.1646 16.426 35.8539 16.7287 35.2324 17.334L29.3357 23.0774C29.1518 23.2565 29.0599 23.346 29.0006 23.4525C28.9481 23.5468 28.9144 23.6504 28.9014 23.7576C28.8868 23.8786 28.9085 24.0051 28.9518 24.258L30.3432 32.3703C30.4899 33.2259 30.5633 33.6537 30.4254 33.9076C30.3054 34.1285 30.0922 34.2834 29.845 34.3292C29.561 34.3819 29.1768 34.1798 28.4084 33.7758L21.1205 29.9431C20.8935 29.8237 20.7799 29.764 20.6603 29.7406C20.5544 29.7198 20.4455 29.7198 20.3396 29.7406C20.22 29.764 20.1064 29.8237 19.8793 29.9431L12.5915 33.7758C11.8231 34.1798 11.4389 34.3819 11.1549 34.3292C10.9077 34.2834 10.6945 34.1285 10.5745 33.9076C10.4366 33.6537 10.51 33.2259 10.6567 32.3703L12.0481 24.258C12.0914 24.0051 12.1131 23.8786 12.0985 23.7576C12.0855 23.6504 12.0518 23.5468 11.9993 23.4525C11.94 23.346 11.848 23.2565 11.6642 23.0774L5.76752 17.334C5.14602 16.7287 4.83528 16.426 4.79746 16.1397C4.76456 15.8906 4.84584 15.6399 5.01867 15.4575C5.21731 15.2478 5.64654 15.1851 6.50499 15.0596L14.6571 13.868C14.9106 13.831 15.0374 13.8125 15.1478 13.7611C15.2455 13.7157 15.3335 13.6517 15.4069 13.5727C15.4898 13.4835 15.5465 13.3686 15.6599 13.1389L19.3043 5.75561Z"
            fill="#27C9D4"
            stroke="#27C9D4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const EmptyIcon = () => (
    <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.3043 5.75561C19.6885 4.97738 19.8806 4.58826 20.1413 4.46394C20.3682 4.35577 20.6317 4.35577 20.8586 4.46394C21.1193 4.58826 21.3114 4.97738 21.6956 5.75561L25.34 13.1389C25.4534 13.3686 25.5101 13.4835 25.593 13.5727C25.6664 13.6517 25.7544 13.7157 25.8521 13.7611C25.9625 13.8125 26.0893 13.831 26.3428 13.868L34.4949 15.0596C35.3534 15.1851 35.7826 15.2478 35.9812 15.4575C36.1541 15.6399 36.2353 15.8906 36.2024 16.1397C36.1646 16.426 35.8539 16.7287 35.2324 17.334L29.3357 23.0774C29.1518 23.2565 29.0599 23.346 29.0006 23.4525C28.9481 23.5468 28.9144 23.6504 28.9014 23.7576C28.8868 23.8786 28.9085 24.0051 28.9518 24.258L30.3432 32.3703C30.4899 33.2259 30.5633 33.6537 30.4254 33.9076C30.3054 34.1285 30.0922 34.2834 29.845 34.3292C29.561 34.3819 29.1768 34.1798 28.4084 33.7758L21.1205 29.9431C20.8935 29.8237 20.7799 29.764 20.6603 29.7406C20.5544 29.7198 20.4455 29.7198 20.3396 29.7406C20.22 29.764 20.1064 29.8237 19.8793 29.9431L12.5915 33.7758C11.8231 34.1798 11.4389 34.3819 11.1549 34.3292C10.9077 34.2834 10.6945 34.1285 10.5745 33.9076C10.4366 33.6537 10.51 33.2259 10.6567 32.3703L12.0481 24.258C12.0914 24.0051 12.1131 23.8786 12.0985 23.7576C12.0855 23.6504 12.0518 23.5468 11.9993 23.4525C11.94 23.346 11.848 23.2565 11.6642 23.0774L5.76752 17.334C5.14602 16.7287 4.83528 16.426 4.79746 16.1397C4.76456 15.8906 4.84584 15.6399 5.01867 15.4575C5.21731 15.2478 5.64654 15.1851 6.50499 15.0596L14.6571 13.868C14.9106 13.831 15.0374 13.8125 15.1478 13.7611C15.2455 13.7157 15.3335 13.6517 15.4069 13.5727C15.4898 13.4835 15.5465 13.3686 15.6599 13.1389L19.3043 5.75561Z"
            stroke="#27C9D4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


const IlustrateTwo = () => {
    const {
        value,
        setValue,
        data,
        loading,
        text,
        setText,
        handleChipClick,
        handleSend,
        selectedParents,
        selectedChildren
    } = useIllustration(QuestionEnum.ORDER);

    return (
        <Box>
            <Paper sx={{ bgcolor: '#FFFFFF', padding: 2, textAlign: 'center', boxShadow: '0px 11px 24px rgba(0, 0, 0, 0.1)', borderRadius: '15px' }}>
                {loading ?
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', margin:'20px 0'}}>
                        <CustomCircularProgress/>
                    </Box>
                :
                    <>
                    <Typography color="primary" fontWeight={700} fontSize={15}>
                        דרגו את החוויה שלכם
                    </Typography>
                    <Rating
                        size="large"
                        value={value}
                        icon={<FullIcon />}
                        emptyIcon={<EmptyIcon />}
                        onChange={(event, newValue) => setValue(newValue)}
                    />
                    {value &&
                        <>
                        <Typography color="primary">
                            אנא עזור לנו להשתפר,<br />
                            נשמח לשמוע ממה הייתם פחות מרוצים:
                        </Typography>
                
                                
                        {data?.map((chip, index) => (
                            <Box key={index}>
                                <Chip
                                    label={chip.parentTitle}
                                    onClick={() => handleChipClick(chip)}
                                    variant={selectedParents.some(item => item.parent === chip.parent) ? "filled" : "outlined"}
                                    color="primary"
                                    sx={{
                                        margin:'5px',
                                        fontSize:'12px',
                                        lineHeight:'19px',
                                        fontWeight:700,
                                        color:themeColors.primary,
                                        borderColor:themeColors.primary,
                                        '&.MuiChip-filled': {
                                            backgroundColor: themeColors.primary,
                                            color: 'white', 
                                        },
                                    }}
                                />
                                {selectedParents.some(item => item.parent === chip.parent) && (
                                    <Box sx={{ marginLeft: 2 }}>
                                        {chip.children.map((child, childIndex) => (
                                            <Chip
                                                key={childIndex}
                                                label={child.title}
                                                onClick={() => handleChipClick(child)}
                                                color="primary"
                                                variant={selectedChildren.some(item => item.id === child.id) ? "filled" : "outlined"}
                                                sx={{
                                                    margin:'5px',
                                                    fontSize:'12px',
                                                    lineHeight:'19px',
                                                    fontWeight:700,
                                                    color:themeColors.primary,
                                                    borderColor:themeColors.primary,
                                                    '&.MuiChip-filled': {
                                                        backgroundColor: themeColors.primary,
                                                        color: 'white', 
                                                    },
                                                }}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        ))}
                        </>
                    }
            
                    <TextField value={text} onChange={(e) =>  setText(e.target.value)} multiline rows={3} fullWidth sx={{ bgcolor: 'white', borderRadius: '14px' }} />
                    {value && value > 0 && 
                    <Button variant="outlined" sx={{ mt: 2 }} onClick={handleSend}>
                        שליחה
                    </Button>
                    }
                    </>
                }
                

            </Paper>
            <Box sx={{display:'flex', justifyContent:'center'}}>
                    <svg width="87" height="18" viewBox="0 0 87 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            d="M-11.4521 -45.5996H68.4396C78.6383 -45.5996 86.8938 -37.3495 86.8938 -27.1874V-18.7495C86.8938 -8.57399 78.6249 -0.337288 68.4396 -0.337288H36.7808V15.9105C36.7808 17.3667 34.9937 18.0659 34.0056 16.9963L18.005 -0.323917H-11.4521C-21.6374 -0.323917 -29.9062 -8.57399 -29.9062 -18.7495V-27.1874C-29.9062 -37.3495 -21.6374 -45.5996 -11.4521 -45.5996Z" 
                            fill="white" 
                            style={{ fill: "white", fillOpacity: 1 }}
                        />
                    </svg>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center'}}> 
                <img src={`${process.env.REACT_APP_MEDIA}/one.png`} width={'204px'} height={'148px'} style={{marginLeft:'180px'}}/>
            </Box>
        </Box>
    );
};

export default IlustrateTwo;
