import { useState } from 'react';
import { QuestionEnum } from '../types/questionEnum';
import useDataQuestion from './useDataQuestion';
import { QuestionService } from '../services/question.service';
import { useNotify } from '../provider/NotifyProvider';
import { useAuth } from '../store/auth.store';
import { useParams } from 'react-router-dom';
import useDataDocumentItems from './useDataDocumentItems';
import useDataMachineDocument from './useDataMachineDocument';

const useIllustration = (questionType: QuestionEnum) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState<number | null>(null);
    const [text, setText] = useState('');
    const { mutate: orderMutate } = useDataDocumentItems(id!)
    const { mutate: serviceMutate } = useDataMachineDocument()
    const { data } = useDataQuestion(questionType);
    const { onSuccessNotify, onErrorNotify } = useNotify();
    const { user } = useAuth();
    const [selectedParents, setSelectedParents] = useState<IQuestion[]>([]);
    const [selectedChildren, setSelectedChildren] = useState<IQuestionChildren[]>([]);

    const handleChipClick = (chip: IQuestion | IQuestionChildren) => {
        if ('parent' in chip) { 
            const isParentSelected = selectedParents.some(item => item.parent === chip.parent);
            if (isParentSelected) {
                setSelectedParents(selectedParents.filter(item => item.parent !== chip.parent));
            } else {
                setSelectedParents([...selectedParents, chip]);
            }
        } else { 
            const isChildSelected = selectedChildren.some(item => item.id === chip.id);
    
            if (isChildSelected) {
                setSelectedChildren(selectedChildren.filter(item => item.id !== chip.id));
            } else {
                setSelectedChildren([...selectedChildren, chip]);
            }
        }
    };

    const handleSend = async () => {
        setLoading(true)
        try {
            QuestionService.createQuestion(
                value ?? 0,
                selectedChildren,
                text,
                user!,
                questionType,
                id!
            ).then(async(e) => {
                if (e.status === 'success') {
                    onSuccessNotify(e.message);
                } else {
                    onErrorNotify(e.message);
                }
                if(questionType === QuestionEnum.ORDER){
                    await orderMutate()
                } 
                if(questionType === QuestionEnum.SERVICE){
                    await serviceMutate()
                }
                setLoading(false)
            });
        } catch (e) {
            console.error('[ERROR]', e);
        } 
    };

    const getFilteredData = () => {
        if (!data || value === null) return [];
    
        return data.filter((item: any) => {
            // If value === 5, show only objects with stars === 5
            if (value === 5) {
                if (item.stars === 5) return true;
    
                return false;
            }
    
            // If value >= 4, show objects with stars >= 4 but exclude stars === 5
            if (value <= 4) {
                if (item.stars >= 4 && item.stars < 5) return true;
                return false;
            }
    
            return false; // Default case, return nothing
        });
    };


    return {
        loading,
        value,
        setValue,
        text,
        setText,
        data: getFilteredData(),
        handleChipClick,
        handleSend,
        selectedParents,
        selectedChildren
    };
};

export default useIllustration;
