import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import Card from '../../components/Home/Card';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { themeColors } from '../../styles';
import LastSkeleton from './LastSkeleton';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { DocumentEnum } from '../../types/documentEnum';

const LastOrders = () => {
    const { data, isLoading } = useDataLastOrders();
    const navigate = useNavigate()

    return (
        <Box sx={{ mt: '10px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                    הזמנות שביצעת לאחרונה
                </Typography>
                <IconButton onClick={() => navigate(`${URLS.DOCUMENTS.LINK}/${DocumentEnum.ORDERS}`)}>
                    <ArrowBackIosRoundedIcon sx={{ color: themeColors.title }} />
                </IconButton>
            </Box>

            {isLoading ? (
                [...Array(5)].map((_, index) => (
                    <LastSkeleton key={index} />
                ))
            ) : (
                <Box>
                    {data?.map((item) => (
                        <Card key={item.documentNumber} item={item} isOrder={true} />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default LastOrders;
