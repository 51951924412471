import React from 'react';
import useDataFaq from '../../hooks/useDataFaq';
import { Box, Divider, List as MuiList } from '@mui/material';
import Card from './Card';
import { useFaq } from '../../store/faq.store';
import CustomCircularProgress from '../Common/Preloader';

const List = () => {
    const { data, isLoading } = useDataFaq();
    const { title } = useFaq();

    const filteredData = data?.filter(item => 
        item.title.toLowerCase().includes(title.toLowerCase()) || 
        item.description.toLowerCase().includes(title.toLowerCase())
    );

    return (
        <>
        {isLoading ?
             <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', margin:'20px 0'}}>
                <CustomCircularProgress/>
            </Box>
        :
            <MuiList>
                {filteredData?.map((item, index) => (
                    <React.Fragment key={index}>
                        <Card item={item} />
                        {index < filteredData.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </MuiList>
        }
        
        </>

    );
};

export default List;
