import { Box } from '@mui/material';
import React, { FC, } from 'react';
import FormControler from './FormControler';


interface CardProps {
    item: IPermissionDetailed
}


const Card:FC<CardProps> = ({item}) => {


    return (
        <Box sx={{padding:'0 10px'}}>
            <FormControler 
                item={item}
                field={'isArchitecture'}
                checkedValue={item.isArchitecture}
                title={'פתיחת הרשאות לעובדים'}
                description={' העובד יוכל להוסיף הרשאות לעובדים אחרים'}
            />
            <FormControler 
                item={item}
                field={'orderable'}
                checkedValue={item.isOrderable}
                title={'ביצוע הזמנה'}
                description={'ההרשאה מאפשרת לבצע הזמנה חדשה וכן לשחזר הזמנה קודמת'}
            />
            <FormControler 
                item={item}
                field={'orderEditable'}
                checkedValue={item.isOrderEditable}
                title={'עדכון וביטול הזמנה'}
                description={'ההרשאה מאפשרת לעדכן ולבטל הזמנה, בהתאם לסטטוס'}
            />
            <FormControler 
                item={item}
                field={'serviceable'}
                checkedValue={item.isServiceable}
                title={'פתיחת קריאת שירות'}
                description={'ההרשאה מאפשרת לפתוח קריאת שירות חדשה למכונה'}
            />
            <FormControler 
                item={item}
                field={'serviceEditable'}
                checkedValue={item.isServiceEditable}
                title={'ביטול ועריכת קריאת שירות'}
                description={'ההרשאה מאפשרת לבטל ולערוך קריאת שירות בהתאם לסטטוס'}
            />
            <FormControler 
                item={item}
                field={'ordersView'}
                checkedValue={item.isOrdersView}
                title={'צפיה בארכיון הזמנות'}
                description={'ההרשאה מאפשרת לצפות בהזמנות קודמות אשר סופקו לבית העסק'}
            />

            <FormControler 
                item={item}
                field={'serviceView'}
                checkedValue={item.isServiceView}
                title={'צפיה בארכיון קריאות שירות'}
                description={'ההרשאה מאפשרת לצפות בקריאות שירות קודמות אשר טופלו'}
            />
            <FormControler 
                item={item}
                field={'deliveryView'}
                checkedValue={item.isDeliveryView}
                title={'צפיה בארכיון תעודות משלוח'}
                description={' ההרשאה מאפשרת לצפות בתעודות משלוח בגין הזמנות שסופקו'}
            />
            <FormControler 
                item={item}
                field={'accounting'}
                checkedValue={item.isAccounting}
                title={'צפיה ב"כספים"'}
                description={'ההרשאה מאפשרת לצפות בחשבוניות מס מרכזות, חשבוניות עסקה וקבלות'}
            />
        </Box>
    );
};

export default Card;