import { Stepper, Step, StepLabel, Typography, styled, Box, StepConnector, stepConnectorClasses } from '@mui/material';
import { themeColors } from '../../styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface StepProps {
  label: string;
}

interface CustomStepperProps {
  steps: StepProps[];
  activeStep: number;
  iconSize?: number; // Optional prop to set icon size
}

// Custom styling for StepLabel to manage icon and label alignment
const CustomStepLabel = styled(StepLabel)<{ completed: boolean; active: boolean }>(({ theme, completed, active }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: themeColors.primary,
    alignItems: 'center',
    '& .MuiStepLabel-label': {
      marginTop: '1px !important', // Set custom margin
      color: `${themeColors.primary} !important` ,
      fontSize:'14px !important',
      fontWeight:700,
      visibility: active ? 'visible' : 'hidden',
    },
  }));
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 5px)',
      right: 'calc(50% + 5px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: themeColors.primary, // Active step should be green
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: themeColors.primary, // Completed step should also be green
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.placeholdersInput,
      borderTopWidth: 3,
      borderRadius: 1,
      ...theme.applyStyles('dark', {
        borderColor: theme.palette.grey[800],
      }),
    },
  }));
const CustomStepper: React.FC<CustomStepperProps> = ({ steps, activeStep, iconSize = 20 }) => {
  return (
    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Stepper sx={{width:'150px'}} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
        {steps.map((step, index) => (
            <Step key={step.label}>
            <CustomStepLabel
                completed={index < activeStep} // Determine if the step is completed
                active={index === activeStep}
                icon={
                index < activeStep ? ( // Check if the step is completed
                    <CheckCircleRoundedIcon sx={{zIndex:1, color: themeColors.primary, width:`${iconSize + 2}px`, height:`${iconSize + 2}px` }} />
                ) : index === activeStep ? (
                    <Box
                    sx={{
                        width: `${iconSize}px`,
                        height: `${iconSize}px`,
                        borderRadius: '50%',
                        border: `1px solid ${themeColors.primary}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'white',
                        zIndex:1,
                        
                    }}
                    >
                    <Typography fontWeight={600} fontSize={12} component="span" sx={{ color: themeColors.primary }} >
                        {index + 1}
                    </Typography>
                    </Box>
                ) : (
                    <Box
                    sx={{
                        marginTop:'4px',
                        width: `${iconSize - 6}px`,
                        height: `${iconSize - 6}px`,
                        bgcolor: themeColors.placeholdersInput,
                        borderRadius: '50%',
                    }}
                    />
                )
                }
            >
                {index === activeStep && step.label}
            </CustomStepLabel>
            </Step>
        ))}
        </Stepper>
    </Box>
  );
};

export default CustomStepper;
