import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { AreaService } from '../services/area.service'
import { ServicesService } from '../services/services.service'
import { useParams } from 'react-router-dom'
import { useArea } from '../store/area.store'

const fetchData = async (
  userExId: string,
  areaCode: string,
  isAvaliable: boolean = false
): Promise<IDocument[]> => {
  return await ServicesService.getLastServices(userExId,areaCode,isAvaliable)
}

const useDataLastServices = (isAvaliable: boolean = false) => {
  const { user } = useAuth()
  const { choosedArea } = useArea()

  const { data, error, isLoading, mutate } = useSWR<IDocument[]>(
    `/client/lastServices/${user?.isPayCustomer ? '200' :choosedArea?.code}/${user?.isPayCustomer ? choosedArea?.code : user?.extId}${isAvaliable ? '?type=avaliable' :''}`,
    () => fetchData(user?.isPayCustomer ? choosedArea?.code! : user?.extId! ,user?.isPayCustomer ? '200' :choosedArea?.code!,isAvaliable)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataLastServices

