import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import URLS from '../../enums/route';
import Draggable from 'react-draggable';
import { Paper, Typography } from '@mui/material';
import { OrderStatus } from '../../enums/orderStatus';

const DRAG_THRESHOLD = 10; // Movement threshold to differentiate between click and drag

const OrderDraggable: React.FC = () => {
    const [openOrders, setOpenOrders] = useState<boolean>(false);
    const { pathname } = useLocation();
    const { data: orders } = useDataLastOrders(true);
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const startPosition = useRef<{ x: number; y: number } | null>(null);

    const handlePointerDown = (event: React.PointerEvent) => {
        setIsDragging(false);
        startPosition.current = { x: event.clientX, y: event.clientY };
    };

    const handlePointerMove = (event: React.PointerEvent) => {
        if (startPosition.current) {
            const dx = Math.abs(event.clientX - startPosition.current.x);
            const dy = Math.abs(event.clientY - startPosition.current.y);
            if (dx > DRAG_THRESHOLD || dy > DRAG_THRESHOLD) {
                setIsDragging(true);
            }
        }
    };

    const handlePointerUp = (item?: { documentNumber: string }) => {
        if (!isDragging) {
            // Handle tap/click
            if (item) {
                navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${item.documentNumber}`);
            } else {
                setOpenOrders(!openOrders);
            }
        }
        setIsDragging(false);
        startPosition.current = null;
    };

    return (
        <>
            {(pathname.includes(URLS.CATALOG.LINK) || pathname.includes(URLS.PRODUCT_PAGE.LINK)) &&
                <>
                    {orders && orders.length > 0 &&
                        <>
                            {!openOrders && orders.length > 8 ?
                                <Draggable>
                                    <Paper
                                        onPointerDown={handlePointerDown}
                                        onPointerMove={handlePointerMove}
                                        onPointerUp={() => handlePointerUp()}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '50%',
                                            background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                            color: 'white',
                                            position: 'relative',
                                            boxShadow: 3,
                                            border: '2px solid white'
                                        }}
                                    >
                                        <img src={`${process.env.REACT_APP_MEDIA}/order.svg`} alt="icon" width={'35px'} height={'35px'} />
                                        <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                            {`${orders.length}`}
                                        </Typography>
                                        <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                            {'הזמנות'}
                                        </Typography>
                                    </Paper>
                                </Draggable>
                                :
                                (openOrders || orders.length < 8) && orders.map((item) =>
                                    <Draggable key={item.documentNumber}>
                                        <Paper
                                            onPointerDown={handlePointerDown}
                                            onPointerMove={handlePointerMove}
                                            onPointerUp={() => handlePointerUp(item)}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '70px',
                                                height: '70px',
                                                borderRadius: '50%',
                                                background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                                color: 'white',
                                                position: 'relative',
                                                boxShadow: 3,
                                                border: '2px solid white'
                                            }}
                                        >
                                            <img src={`${process.env.REACT_APP_MEDIA}/${item.status === OrderStatus.Delivered ? 'orderComplete' : 'order'}.svg`} alt="icon" width={'35px'} height={'35px'} />
                                            <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                                {'הזמנה'}
                                            </Typography>
                                            <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                                {`${item.documentNumber.slice(-4)}`}
                                            </Typography>
                                        </Paper>
                                    </Draggable>
                                )
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default OrderDraggable;
