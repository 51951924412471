import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'
import { CatalogService } from '../services/catalog.service'
import { DocumentService } from '../services/document.service'

interface useCartState {
  loading: boolean
  cart: ICart[]
  setCart: (cart: ICart[]) => void
  clearCart: () => void
  getCartItem: (product: { sku: string }) => ICart | null
  addToCart: (product: IProduct) => void
  increaseCart: (sku: string) => void
  decreaseCart: (sku: string) => void
  deleteFromCart: (sku: string) => void
  changeQuantity: (sku: string, quantity: number) => void
  deliveryDate: Date
  setDeliveryDate: (deliveryDate: Date) => void
  sendOrder: (
    user: IUser,
    deliveryDate: Date,
    area: IArea
  ) => Promise<ISendOrderResponse>
}

export const useCart = create(
  persist(
    (set, get) => ({
      loading: false,
      cart: [],
      setCart: (cart: ICart[]) => set({cart}),
      clearCart: () => {set({cart:[]})},
      getCartItem: (product): ICart | null => {
        const cart = get().cart
        const itemFind = cart.find((item) => item.sku === product.sku)
        if (itemFind) {
          return itemFind
        } else {
          return null
        }
      },
      addToCart: (product: IProduct) => {
        const { cart } = get()
        const cartProduct = {
          sku: product.sku,
          quantity: 1,
          product: product,
        }
        set({ cart: [...cart, cartProduct] })
      },
      increaseCart: (sku) => {
        if(!get().loading){
          const cart = get().cart
          const itemIndex = cart.findIndex((item) => item.sku === sku)
          if (itemIndex !== -1) {
            cart[itemIndex].quantity += 1
          } else {
            console.error('Item not found in cart')
          }
          set({ cart })
        }
      },
      decreaseCart: (sku) => {
        if(!get().loading){
          const cart = get().cart
          const itemIndex = cart.findIndex((item) => item.sku === sku)
          if (itemIndex !== -1) {
            const newQuantity = cart[itemIndex].quantity - 1
            if (newQuantity <= 0) {
              const filteredCart = cart.filter((item) => item.sku !== sku)
              set({ cart: filteredCart })
            } else {
              cart[itemIndex].quantity = newQuantity
              set({ cart })
            }
          } else {
            console.error('Item not found in cart')
          }
        }
      },
      deleteFromCart: (sku) => {
        const cart = get().cart
        const filtered = cart.filter((item) => item.sku !== sku)
        set({ cart: filtered })
      },
      changeQuantity: (sku, quantity) => {
        const cart = get().cart
        const itemIndex = cart.findIndex((item) => item.sku === sku)
        if (itemIndex !== -1) {
          cart[itemIndex].quantity = +quantity
        } else {
          console.error('Item not found in cart')
        }
        set({ cart })
      },
      deliveryDate: new Date,
      setDeliveryDate:  (deliveryDate) => set({deliveryDate}),
      sendOrder: async (user: IUser, deliveryDate: Date, area: IArea) => {
        try {
          set({ loading: true })
          const response = await DocumentService.sendOrder(user,deliveryDate,area,get().cart)
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בשידור' + e , data: null}
        } finally {
          set({ loading: false })
        }
      }
    }),
    {
      name: 'cart-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<useCartState, useCartState>
  )
)
