import React from 'react';
import Layout from '../components/Layout';
import Suppport from '../components/Suppport';
import SecondPageLayout from '../components/Layout/SecondPageLayout';

const SupportPage = () => {
    return (
        <SecondPageLayout withFooter={true}>
            <Suppport.Content/>
        </SecondPageLayout>
    );
};

export default SupportPage;