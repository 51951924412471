import { Box, IconButton, Typography, Collapse, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import useDataCompanyUsers from '../../hooks/useDataCompanyUsers';
import UserCard from './UserCard';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ModalWrapper from '../Modals/ModalWrapper';
import CreateModal from './CreateModal';
import CustomCircularProgress from '../Common/Preloader';

const CompanyUsersList = () => {
    const { data, isLoading } = useDataCompanyUsers();
    const [expanded, setExpanded] = useState(true);
    const [openModal, setOpenModal] = useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <Box>
            {isLoading ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', margin:'20px 0'}}>
                    <CustomCircularProgress/>
                </Box>
            :
            <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', mt:'20px', alignItems:'center' }} onClick={handleExpandClick}>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Typography color='#154D72' fontWeight={700} lineHeight={'21px'} fontSize={'15px'}>
                        עובדים בארגון {`(${data?.length})`}
                    </Typography>
                </Box>
                <Box>
                    {expanded &&
                        <IconButton
                        onClick={(e) => {setOpenModal(true); e.stopPropagation();}}
                        >
                            <AddCircleOutlineRoundedIcon sx={{color:'#154D72'}}/>
                        </IconButton>
                    }
                    <IconButton aria-expanded={expanded} aria-label="show more">
                        {expanded ? <ExpandLessIcon sx={{color:'#154D72'}}/> : <ExpandMoreIcon sx={{color:'#154D72'}}/>}
                    </IconButton>
                </Box>
            </Box>
            }


            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {data?.map((item, index) => (
                    <UserCard key={index} item={item} />
                ))}
            </Collapse>
            <CreateModal openModal={openModal} setOpenModal={setOpenModal}/>
        </Box>
    );
};

export default CompanyUsersList;
