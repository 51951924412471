import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import { Box, Paper, Typography } from '@mui/material';
import URLS from '../../enums/route';
import { useCart } from '../../store/cart.store';
import { useDocuments } from '../../store/documents.store';

const DRAG_THRESHOLD = 10; // Movement threshold to differentiate between click and drag

const EditOrderDraggable: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { cart } = useCart();
    const { choosedDocument } = useDocuments();
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const startPosition = useRef<{ x: number; y: number } | null>(null);

    const handlePointerDown = (event: React.PointerEvent) => {
        setIsDragging(false);
        startPosition.current = { x: event.clientX, y: event.clientY };
    };

    const handlePointerMove = (event: React.PointerEvent) => {
        if (startPosition.current) {
            const dx = Math.abs(event.clientX - startPosition.current.x);
            const dy = Math.abs(event.clientY - startPosition.current.y);
            if (dx > DRAG_THRESHOLD || dy > DRAG_THRESHOLD) {
                setIsDragging(true);
            }
        }
    };

    const handlePointerUp = () => {
        if (!isDragging) {
            navigate(
                choosedDocument
                    ? `${URLS.DOCUMENTS_ITEM.LINK}/${choosedDocument.documentNumber}`
                    : `${URLS.CART.LINK}`
            );
        }
        setIsDragging(false);
        startPosition.current = null;
    };

    return (
        <>
            {(pathname.includes(URLS.CATALOG.LINK) || pathname.includes(URLS.PRODUCT_PAGE.LINK)) &&
                <>
                    {cart?.length > 0 &&
                        <Draggable>
                            <Paper
                                onPointerDown={handlePointerDown}
                                onPointerMove={handlePointerMove}
                                onPointerUp={handlePointerUp}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '70px',
                                    height: '70px',
                                    borderRadius: '50%',
                                    background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                    color: 'white',
                                    position: 'relative',
                                    boxShadow: 3,
                                    border: '2px solid white'
                                }}
                            >
                                <img src={`${process.env.REACT_APP_MEDIA}/cart.svg`} alt="icon" width={'35px'} height={'35px'} />
                                <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                    {choosedDocument ?
                                        <>
                                            עדכון הזמנה {choosedDocument.documentNumber}
                                        </>
                                        :
                                        <>
                                            הזמנה 
                                        </>
                                    }
                                </Typography>
                                <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                    {choosedDocument ?
                                        <>
                                            {choosedDocument.documentNumber}
                                        </>
                                        :
                                        <>
                                                
                                        </>
                                    }
                                </Typography>
                            </Paper>
                        </Draggable>
                    }
                </>
            }
        </>
    );
};

export default EditOrderDraggable;
