import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { AreaService } from '../services/area.service'
import { useArea } from '../store/area.store'
import { CatalogService } from '../services/catalog.service'

const fetchData = async (
  userExId: string,
  areaCode: string
): Promise<ICatalog[]> => {
  return await CatalogService.getCatalog(userExId,areaCode)
}

const useDataCatalog = () => {
  const { user } = useAuth()
  const { choosedArea } = useArea()
  const { data, error, isLoading, mutate } = useSWR<ICatalog[]>(
    `/client/products/${user?.isPayCustomer ? choosedArea?.code : user?.extId}/${user?.isPayCustomer ? '200' : choosedArea?.code}`,
    () => fetchData(user?.isPayCustomer ? choosedArea?.code! : user?.extId! ,user?.isPayCustomer ? '200' : choosedArea?.code!)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataCatalog

