import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import URLS from '../../enums/route';
import { DocumentEnum } from '../../types/documentEnum';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../store/auth.store';

const list1 = [
    {
        title: <>
        קריאות <br/> שירות
        </>,
        img: 'docmenu1.png',
        link: `${URLS.DOCUMENTS.LINK}/${DocumentEnum.SERVICES}`,
        type: DocumentEnum.SERVICES
    },
    {
        title: 'הזמנות',
        img: 'docmenu2.png',
        link: `${URLS.DOCUMENTS.LINK}/${DocumentEnum.ORDERS}`,
        type: DocumentEnum.ORDERS
    },
    {
        title: <>
        תעודות<br/>משלוח
        </>,
        img: 'docmenu3.png',
        link: `${URLS.DOCUMENTS.LINK}/${DocumentEnum.DELIVERY}`,
        type: DocumentEnum.DELIVERY
    },
];

const list2 = [
    {
        title: 'קבלות',
        img: 'docmenu4.png',
        link: `${URLS.DOCUMENTS.LINK}/${DocumentEnum.INVOICES}`,
    },
    {
        title: <>חשבוניות<br/> מס</>,
        img: 'docmenu5.png',
        link: `${URLS.DOCUMENTS.LINK}/${DocumentEnum.INVOICES_MAS}`,
    },
    {
        title: <>חשבוניות<br/> עסקה</>,
        img: 'docmenu6.png',
        link: `${URLS.DOCUMENTS.LINK}/${DocumentEnum.INVOICES_DEAL}`,
    },
];

const Default = () => {
    const navigate = useNavigate();
    const {user} = useAuth()
    const handlePermissionView = (type: DocumentEnum) => {
        if(type === DocumentEnum.SERVICES && user?.permission?.permissionDetaileds[0]?.isServiceView) {
            return true
        } else if(type === DocumentEnum.ORDERS && user?.permission?.permissionDetaileds[0]?.isOrdersView) {
            return true
        } else if(type === DocumentEnum.DELIVERY && user?.permission?.permissionDetaileds[0]?.isDeliveryView) {
            return true
        } else {
            return false
        }
    }
    console.log('user',user)
    return (
        <Box sx={{padding:'0 10px 50px 10px'}}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Component 2">
                    <path
                    id="Icon"
                    d="M20.5 8V16.2C20.5 17.8802 20.5 18.7202 20.173 19.362C19.8854 19.9265 19.4265 20.3854 18.862 20.673C18.2202 21 17.3802 21 15.7 21H8.3C6.61984 21 5.77976 21 5.13803 20.673C4.57354 20.3854 4.1146 19.9265 3.82698 19.362C3.5 18.7202 3.5 17.8802 3.5 16.2V8M3.6 3H20.4C20.9601 3 21.2401 3 21.454 3.10899C21.6422 3.20487 21.7951 3.35785 21.891 3.54601C22 3.75992 22 4.03995 22 4.6V6.4C22 6.96005 22 7.24008 21.891 7.45399C21.7951 7.64215 21.6422 7.79513 21.454 7.89101C21.2401 8 20.9601 8 20.4 8H3.6C3.03995 8 2.75992 8 2.54601 7.89101C2.35785 7.79513 2.20487 7.64215 2.10899 7.45399C2 7.24008 2 6.96005 2 6.4V4.6C2 4.03995 2 3.75992 2.10899 3.54601C2.20487 3.35785 2.35785 3.20487 2.54601 3.10899C2.75992 3 3.03995 3 3.6 3ZM9.6 11.5H14.4C14.9601 11.5 15.2401 11.5 15.454 11.609C15.6422 11.7049 15.7951 11.8578 15.891 12.046C16 12.2599 16 12.5399 16 13.1V13.9C16 14.4601 16 14.7401 15.891 14.954C15.7951 15.1422 15.6422 15.2951 15.454 15.391C15.2401 15.5 14.9601 15.5 14.4 15.5H9.6C9.03995 15.5 8.75992 15.5 8.54601 15.391C8.35785 15.2951 8.20487 15.1422 8.10899 14.954C8 14.7401 8 14.4601 8 13.9V13.1C8 12.5399 8 12.2599 8.10899 12.046C8.20487 11.8578 8.35785 11.7049 8.54601 11.609C8.75992 11.5 9.03995 11.5 9.6 11.5Z"
                    stroke="#2F4B6C"
                    style={{ stroke: "#2F4B6C", strokeOpacity: 1 }}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                </g>
                </svg>
                <Typography variant="h5" color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>ארכיון</Typography>
            </Box>
            <Typography sx={{ mb: 4 }} color='#436F7C' fontSize={'13px'} fontWeight={400}>
                ארכיון מסמכים, פעולות, הזמנות וקריאות שירות
            </Typography>
            {
            (
            !user?.permission?.permissionDetaileds[0]?.isServiceView &&
            !user?.permission?.permissionDetaileds[0]?.isOrdersView &&
            !user?.permission?.permissionDetaileds[0]?.isDeliveryView 
            )   
            ? null
            :
            <Box>
                <Typography variant="h6" sx={{ mb: 2 }} color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                    ארכיון
                </Typography>
                <Box sx={{ display: 'flex', gap:'10px', mb: 4, justifyContent:'center' }}>
                    {list1.map((item,key) => {
                        const isShow = handlePermissionView(item.type)
                        if(!isShow) return null
                        return (
                        <Paper
                            key={key}
                            sx={{
                                position:'relative',
                                width: '120px',
                                height: '120px',
                                borderRadius: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => navigate(item.link)}
                        >
            
                            <Typography 
                                variant="body2"
                                sx={{
                                    position:'absolute',
                                    top:'15px',
                                    fontSize:'15px',
                                    fontWeight:700,
                                    lineHeight:'16.5px',
                                }}
                                color='#154D72'
                                textAlign={'center'}
                            >
                                {item.title}
                            </Typography>
                            <img
                                src={`${process.env.REACT_APP_MEDIA}/${item.img}`}
                                alt={'doc'}
                                style={{
                                    position:'absolute',
                                    bottom:'0px'
                                }}
                                height="auto"
                            />
                        </Paper>
                        )
                    })}
                </Box>
            </Box>

            }
          
            {user?.permission?.permissionDetaileds[0]?.isAccounting &&
                <Box>
                   <Typography variant="h6" sx={{ mb: 2 }} color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                       הנהח״ש וכספים
                   </Typography>
                   <Box sx={{ display: 'flex', gap:'10px', justifyContent:'center' }}>
                       {list2.map((item,key) => (
                           <Paper
                               key={key}
                               sx={{
                                   position:'relative',
                                   width: '120px',
                                   height: '120px',
                                   borderRadius: '15px',
                                   display: 'flex',
                                   flexDirection: 'column',
                                   alignItems: 'center',
                                   justifyContent: 'center',
                                   gap: '10px',
                                   boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                                   cursor: 'pointer',
                                   transition: 'transform 0.3s ease',
                                   '&:hover': {
                                       transform: 'scale(1.05)',
                                   },
                               }}
                               onClick={() => navigate(item.link)}
                           >
                    
                               <Typography 
                                   variant="body2"
                                   sx={{
                                       position:'absolute',
                                       top:'15px',
                                       fontSize:'15px',
                                       fontWeight:700,
                                       lineHeight:'16.5px',                                
                                    }}
                                    color='#154D72'
                                    textAlign={'center'}
                               >
                                   {item.title}
                               </Typography>
                               <img
                                   src={`${process.env.REACT_APP_MEDIA}/${item.img}`}
                                   alt={'doc'}
                                   style={{
                                       position:'absolute',
                                       bottom:'0px'
                                   }}
                                   height="auto"
                               />
                           </Paper>
                       ))}
                   </Box>
               </Box>
            }
     
        </Box>
    );
};

export default Default;
