import { QuestionEnum } from "../types/questionEnum";
import { ApiInterceptor } from "./api.interceptor";

export const QuestionService = {
    async getQuestions(questionEnum:QuestionEnum){
        const response = await ApiInterceptor<IQuestion[]>(`/client/questions/${questionEnum}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async createQuestion(stars: number, tags: IQuestionChildren[], text: string, user: IUser, questionEnum:QuestionEnum, documentNumber: string): Promise<ApiResponse>{
        const response = await ApiInterceptor<ApiResponse>(`/client/questions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({stars,tags,text,user,questionEnum,documentNumber})
        });
        return response
    }
}