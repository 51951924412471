import { Avatar, Backdrop, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React from 'react';
import { useArea } from '../store/area.store';
import { useAuth } from '../store/auth.store';
import useDataArea from '../hooks/useDataArea';
import { themeColors } from '../styles';

const AreaSelector = () => {

    const {choosedArea,setChoosedArea} = useArea()
    const {user} = useAuth()
    const { data } = useDataArea()

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        const selectedArea = data!.find(item => item.code === selectedValue) || null;
        setChoosedArea(selectedArea);
    };
    console.log('data',data)
    return (
        <React.Fragment>
            <Select
                value={choosedArea?.code || ''}
                onChange={handleChange}
                sx={{ 
                    width: '220px',
                    '& .MuiSelect-select': { padding: '0px' }, 
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, 
                    color:'#80A8B6'
                }}
                MenuProps={{
                    BackdropComponent: (props) => <Backdrop {...props} sx={{ background:'none' }} />,
                    PaperProps: {
                        sx: {
                            borderBottomLeftRadius: '14px',
                            borderBottomRightRadius: '14px',
                        },
                    },
                }}

            >
                {data?.map((item, index) => (
                    <MenuItem 
                        value={item.code} 
                        key={index}
                    >
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </React.Fragment>
    );
};

export default AreaSelector;