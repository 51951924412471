import React, { useEffect } from 'react';
import { BottomNavigation } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/HomePage';
import URLS from './enums/route';
import HomeAuth from './pages/HomeAuth';
import AuthStepOne from './pages/AuthStepOne';
import AuthStepTwo from './pages/AuthStepTwo';
import AuthStepThree from './pages/AuthStepThree';
import ResetPasswordStepOne from './pages/ResetPasswordStepOne';
import ResetPasswordStepTwo from './pages/ResetPasswordStepTwo';
import ResetPasswordStepThree from './pages/ResetPasswordStepThree';
import Login from './pages/Login';
import HomePage from './pages/HomePage';
import CatalogPage from './pages/CatalogPage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import MenuPage from './pages/MenuPage';
import DocumentsPage from './pages/DocumentsPage';
import ServicePage from './pages/ServicePage';
import MachinePage from './pages/MachinePage';
import ServiceDocumentPage from './pages/ServiceDocumentPage';
import DocumentItemsPage from './pages/DocumentItemsPage';
import BussniesPage from './pages/BussniesPage';
import PermissionPage from './pages/PermissionPage';
import NotificationsPage from './pages/NotificationsPage';
import FaqPage from './pages/FaqPage';
import DocumentsMenuPage from './pages/DocumentsMenuPage';
import ChooseAreaDocsPage from './pages/ChooseAreaDocsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SupportPage from './pages/SupportPage';
const RouterApp = () => {

    const location = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [location])

    return (
        <>
        <main style={{minHeight:'100vh'}}>
            <Routes>
                <Route>
                    {/* AUTH PAGES */}
                    <Route path={URLS.AuthHome.ROUTER} element={<HomeAuth/>} />
                    <Route path={URLS.AuthLogin.ROUTER} element={<Login/>} />
                    <Route path={URLS.AuthStepOne.ROUTER} element={<AuthStepOne/>} />
                    <Route path={URLS.AuthStepTwo.ROUTER} element={<AuthStepTwo/>} />
                    <Route path={URLS.AuthStepThree.ROUTER} element={<AuthStepThree/>} />
                    <Route path={URLS.RESET_PASSWORD_STEP_ONE.ROUTER} element={<ResetPasswordStepOne/>} />
                    <Route path={URLS.RESET_PASSWORD_STEP_TWO.ROUTER} element={<ResetPasswordStepTwo/>} />
                    <Route path={URLS.RESET_PASSWORD_STEP_THREE.ROUTER} element={<ResetPasswordStepThree/>} />
                    {/* HOME PAGE */}
                    <Route path={URLS.HOME.ROUTER} element={<HomePage/>} />
                    <Route path={URLS.CATALOG.ROUTER} element={<CatalogPage/>} />
                    <Route path={URLS.PRODUCT_PAGE.ROUTER} element={<ProductPage/>} />
                    <Route path={URLS.CART.ROUTER} element={<CartPage/>} />
                    <Route path={URLS.MENU_PAGE.ROUTER} element={<MenuPage/>} />
                    <Route path={URLS.DOCUMENTS.ROUTER} element={<DocumentsPage/>} />
                    <Route path={URLS.DOCUMENTS_ITEM.ROUTER} element={<DocumentItemsPage/>} />
                    <Route path={URLS.NOTIFICATIONS.ROUTER} element={<NotificationsPage/>} />
                    <Route path={URLS.DOCUEMNTS_MENU.ROUTER} element={<DocumentsMenuPage/>} />
                    <Route path={URLS.DOCUEMNTS_MENU_CHOOSE_AREA.ROUTER} element={<ChooseAreaDocsPage/>} />
                    <Route path={URLS.SERVICE.ROUTER} element={<ServicePage/>} />
                    <Route path={URLS.MACHINE_PAGE.ROUTER} element={<MachinePage/>} />
                    <Route path={URLS.SERVICE_DOCUMENT_PAGE.ROUTER} element={<ServiceDocumentPage/>} />
                    <Route path={URLS.BUSSNIES.ROUTER} element={<BussniesPage/>} />
                    <Route path={URLS.PERMISSION.ROUTER} element={<PermissionPage/>} />
                    <Route path={URLS.FAQ.ROUTER} element={<FaqPage/>} />
                    <Route path={URLS.POLICY_PAGE.ROUTER} element={<PrivacyPolicyPage/>} />
                    <Route path={URLS.SUPPORT.ROUTER} element={<SupportPage/>} />
                </Route>
            </Routes>
        </main>
        </>
    );
};

export default RouterApp;